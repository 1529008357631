/* eslint-disable @typescript-eslint/member-ordering */
import { interval, Observable, Subscription } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AlphabetSoup } from '@api/models/alphabet-soup.entity';
import { Team } from '@api/models/enums';
import { AlphabetSoupRoundScore } from '@bussiness/custom-models/alphabet-soup-phase.custom-models';
import {
	AlphabetSoupRoundTimeChangedEvent,
	AlphabetSoupRoundUpdatedEvent,
} from '@core/models/games/events/alphabet-soup-phase.events';
import { AlphabetSoupPhaseSockets } from '@core/services/sockets/alphabet-soup-phase.sockets';
import { Store } from '@ngrx/store';

import { GamesSelectors } from '../../games.selectors';
import { PlayersFacade } from '../../players/players.facade';
import { AlphabetSoupRoundActions } from './alphabet-soup-round.actions';
import { AlphabetSoupRoundSelectors } from './alphabet-soup-round.selectors';

const ONE_SECOND = 1000;

@Injectable()
export class AlphabetSoupRoundFacade {
	public playerRoundId$: Observable<string> =
		this.playersFacade.current$.pipe(
			withLatestFrom(this.store.select(AlphabetSoupRoundSelectors.all)),
			map(([player, rounds]) => {
				if (!player) return null;

				const round = rounds.find(({ team }) => team === player.team);
				return round._id;
			}),
		);
	public team$ = (roundId: string): Observable<Team> =>
		this.store.select(AlphabetSoupRoundSelectors.team(roundId));

	public soups$ = (roundId: string): Observable<AlphabetSoup[]> =>
		this.store.select(AlphabetSoupRoundSelectors.soups(roundId));

	public scores$ = (roundId: string): Observable<AlphabetSoupRoundScore> =>
		this.store.select(AlphabetSoupRoundSelectors.scores(roundId));

	public playing$ = (roundId: string): Observable<boolean> =>
		this.store.select(AlphabetSoupRoundSelectors.playing(roundId));

	public over$ = (roundId: string): Observable<boolean> =>
		this.store.select(AlphabetSoupRoundSelectors.over(roundId));

	public score$ = (team: Team): Observable<number> =>
		this.store.select(AlphabetSoupRoundSelectors.score(team));

	private get _intervalSubscriptionIsOpen(): boolean {
		return this._intervalSubscription && !this._intervalSubscription.closed;
	}

	private _interval$: Observable<number> = interval(ONE_SECOND);
	private _intervalSubscription: Subscription;

	constructor(
		private store: Store,
		private playersFacade: PlayersFacade,
		private alphabetSoupPhaseSockets: AlphabetSoupPhaseSockets,
	) {
		this.alphabetSoupPhaseSockets.roundTimeChanged$.subscribe(
			({ roundId, time }: AlphabetSoupRoundTimeChangedEvent) =>
				this.store.dispatch(
					AlphabetSoupRoundActions.changeTimeSuccess({
						roundId,
						time,
					}),
				),
		);

		this.alphabetSoupPhaseSockets.roundUpdated$.subscribe(
			({ round }: AlphabetSoupRoundUpdatedEvent) => {
				this.store.dispatch(
					AlphabetSoupRoundActions.updateSuccess({ round }),
				);

				if (round.over) this.toggleTimer(round._id, false);
			},
		);
	}

	public tooglePlaying(roundId: string, play: boolean): void {
		this.store.dispatch(
			AlphabetSoupRoundActions.togglePlaying({ roundId, play }),
		);
		this.toggleTimer(roundId, play);
	}

	private toggleTimer(roundId: string, playing: boolean): void {
		if (playing && this._intervalSubscriptionIsOpen) return;

		if (!playing && this._intervalSubscriptionIsOpen) {
			this._intervalSubscription.unsubscribe();
		}

		if (!playing) return;

		this._intervalSubscription = this._interval$
			.pipe(
				withLatestFrom(
					this.store.select(
						AlphabetSoupRoundSelectors.scores(roundId),
					),
					this.store.select(GamesSelectors.id),
				),
				tap(
					([, scores, gameId]: [
						number,
						AlphabetSoupRoundScore,
						string,
					]) => {
						const { time } = scores;

						const newTime = time - 1;
						this.alphabetSoupPhaseSockets.emitTimeChanged({
							gameId,
							roundId,
							time: newTime,
						});

						if (newTime === 0) {
							this.store.dispatch(
								AlphabetSoupRoundActions.togglePlaying({
									roundId,
									play: false,
									timeOut: true,
								}),
							);
							this._intervalSubscription.unsubscribe();
						}
					},
				),
			)
			.subscribe();
	}
}
