/* eslint-disable @typescript-eslint/member-ordering */
import { Observable, of, timer } from 'rxjs';
import { delayWhen, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AlphabetSoupAnswer } from '@api/models/alphabet-soup-answer.entity';
import { AlphabetSoup } from '@api/models/alphabet-soup.entity';
import { Player } from '@api/models/player.entity';
import { AlphabetSoupFixFormValue } from '@bussiness/components/alphabet-soup-fix/alphabet-soup-fix.forms';
import { AlphabetSoupRoundScore } from '@bussiness/custom-models/alphabet-soup-phase.custom-models';
import { DeviceFacade } from '@bussiness/store/features/device/device.facade';
import { AlphabetSoupUpdatedEvent } from '@core/models/games/events/alphabet-soup-phase.events';
import { AlphabetSoupPhaseSockets } from '@core/services/sockets/alphabet-soup-phase.sockets';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PlayersFacade } from '../../players/players.facade';
import { AlphabetSoupRoundFacade } from '../alphabet-soup-round/alphabet-soup-round.facade';
import { AlphabetSoupActions } from './alphabet-soup.actions';
import { AlphabetSoupSelectors } from './alphabet-soup.selectors';

const FINISH_DELAY = 1000;
@Injectable()
export class AlphabetSoupFacade {
	public current$ = (roundId: string): Observable<AlphabetSoup> =>
		this.store.select(AlphabetSoupSelectors.current(roundId));

	public one$ = (roundId: string, soupId: string): Observable<AlphabetSoup> =>
		this.store.select(AlphabetSoupSelectors.one(roundId, soupId));

	public answers$ = (
		roundId: string,
		soupId: string,
	): Observable<AlphabetSoupAnswer[]> =>
		this.store.select(AlphabetSoupSelectors.answers(roundId, soupId));

	public clue$ = (roundId: string, soupId: string): Observable<string> =>
		this.store.select(AlphabetSoupSelectors.clue(roundId, soupId));

	public covered$ = (roundId: string, soupId: string): Observable<boolean> =>
		this.store.select(AlphabetSoupSelectors.covered(roundId, soupId));

	public turnOwner$ = (roundId: string, soupId: string): Observable<Player> =>
		this.store
			.select(AlphabetSoupSelectors.turnOwner(roundId, soupId))
			.pipe(
				withLatestFrom(this.playersFacade.all$),
				map(([turnOwnerId, players]: [string, Player[]]) =>
					players.find(({ _id }) => _id === turnOwnerId),
				),
			);

	constructor(
		private store: Store,
		private playersFacade: PlayersFacade,
		private alphabetSoupRoundFacade: AlphabetSoupRoundFacade,
		private deviceFacade: DeviceFacade,
		alphabetSoupPhaseSockets: AlphabetSoupPhaseSockets,
	) {
		alphabetSoupPhaseSockets.soupUpdated$
			.pipe(
				delayWhen(({ soup }) =>
					soup.over ? timer(FINISH_DELAY) : of(undefined),
				),
				concatLatestFrom(({ roundId }: AlphabetSoupUpdatedEvent) => [
					this.alphabetSoupRoundFacade.scores$(roundId),
				]),
			)
			.subscribe(
				([event, scores]: [
					AlphabetSoupUpdatedEvent,
					AlphabetSoupRoundScore,
				]) => {
					const { roundId, soup } = event;
					const { rebounds: previousRebounds } = scores;

					if (soup.rebounds !== previousRebounds)
						this.deviceFacade.playAudio('miss-2');

					this.store.dispatch(
						AlphabetSoupActions.updateSuccess({
							roundId,
							soup,
						}),
					);
				},
			);

		alphabetSoupPhaseSockets.roundTimeChanged$
			.pipe(
				filter(({ time }) => time === 0),
				tap(() => this.deviceFacade.playAudio('time-over')),
			)
			.subscribe();
	}

	public rebound(roundId: string): void {
		this.store.dispatch(AlphabetSoupActions.rebound({ roundId }));
	}

	public fix(
		roundId: string,
		soupId: string,
		fixes: AlphabetSoupFixFormValue,
	): void {
		this.store.dispatch(
			AlphabetSoupActions.fix({
				roundId,
				soupId,
				rebounds: fixes.rebounds,
				answers: fixes.answers,
				turnOwner: fixes.turnOwner,
			}),
		);
	}
}
