import { AlphabetSoupPhase } from '@api/models/alphabet-soup-phase.entity';
import { PhaseKind } from '@api/models/enums';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../games.reducer';
import { selectGames } from '../games.selectors';

export class AlphabetSoupPhaseSelectors {
	public static roundIds = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const phase = getAlphabetSoupPhase(gamesState);

			if (!phase) return [];

			return phase.rounds.map(({ _id }) => _id);
		},
	);

	public static rounds = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const phase = getAlphabetSoupPhase(gamesState);

			if (!phase) return [];

			return phase.rounds;
		},
	);

	public static over = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const phase = getAlphabetSoupPhase(gamesState);

			if (!phase) return null;

			return phase.over;
		},
	);
}

export const getAlphabetSoupPhase = (
	gamesState: GamesState,
): AlphabetSoupPhase | null => {
	if (!gamesState.current) return null;
	const { phases } = gamesState.current;

	return phases.find(
		({ kind }) => kind === PhaseKind.Soups,
	) as AlphabetSoupPhase;
};
