/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AlphabetSoup } from '@api/models/alphabet-soup.entity';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import { selectGames } from '../../games.selectors';
import { getAlphabetSoupRound } from '../alphabet-soup-round/alphabet-soup-round.selectors';

export class AlphabetSoupSelectors {
	public static current = (roundId: string) =>
		createSelector(selectGames, (gameState: GamesState) => {
			const round = getAlphabetSoupRound(gameState, roundId);

			if (!round) return null;

			return round.soups.find(({ over }) => !over) || round.soups.at(-1);
		});

	public static one = (roundId: string, soupId: string) =>
		createSelector(selectGames, (gameState: GamesState) => {
			const round = getAlphabetSoupRound(gameState, roundId);

			if (!round) return null;

			return round.soups.find(({ _id }) => soupId === _id);
		});

	public static answers = (roundId: string, soupId: string) =>
		createSelector(selectGames, (gameState: GamesState) => {
			const soup = getAlphabetSoup(gameState, roundId, soupId);

			if (!soup) return null;

			return soup.answers;
		});

	public static clue = (roundId: string, soupId: string) =>
		createSelector(selectGames, (gameState: GamesState) => {
			const soup = getAlphabetSoup(gameState, roundId, soupId);

			if (!soup) return null;

			return soup.clue;
		});

	public static turnOwner = (roundId: string, soupId: string) =>
		createSelector(selectGames, (gameState: GamesState) => {
			const soup = getAlphabetSoup(gameState, roundId, soupId);

			if (!soup) return null;

			return soup.turnOwner;
		});

	public static covered = (roundId: string, soupId: string) =>
		createSelector(selectGames, (gameState: GamesState) => {
			const soup = getAlphabetSoup(gameState, roundId, soupId);

			if (!soup) return null;

			return soup.covered;
		});
}

const getAlphabetSoup = (
	gamesState: GamesState,
	alphabetSoupRoundId: string,
	alphabetSoupId: string,
): AlphabetSoup | null => {
	const round = getAlphabetSoupRound(gamesState, alphabetSoupRoundId);

	if (!round) return null;

	const { soups } = round;
	return soups.find(({ _id }) => _id === alphabetSoupId);
};
