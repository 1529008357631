/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AlphabetSoupRound } from '@api/models/alphabet-soup-round.entity';
import { AlphabetSoupAnswerStatus, Team } from '@api/models/enums';
import { createSelector } from '@ngrx/store';

import { GamesState } from '../../games.reducer';
import { selectGames } from '../../games.selectors';
import { getAlphabetSoupPhase } from '../alphabet-soup-phase.selectors';

export class AlphabetSoupRoundSelectors {
	public static all = createSelector(
		selectGames,
		(gamesState: GamesState) => {
			const phase = getAlphabetSoupPhase(gamesState);

			if (!phase) return null;

			return phase.rounds;
		},
	);

	public static team = (roundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const round = getAlphabetSoupRound(gamesState, roundId);

			if (!round) return null;

			return round.team;
		});

	public static soups = (roundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const round = getAlphabetSoupRound(gamesState, roundId);

			if (!round) return null;

			return round.soups;
		});

	public static scores = (roundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const round = getAlphabetSoupRound(gamesState, roundId);

			if (!round) return null;

			const soup =
				round.soups.find(({ over }) => !over) ?? round.soups.at(-1);

			const soupIndex = round.soups.findIndex(({ over }) => !over);

			return {
				time: round.time,
				hits: soup.answers.filter(
					({ status }) => status === AlphabetSoupAnswerStatus.Hit,
				).length,
				total: soup.answers.length,
				rebounds: soup.rebounds,
				soup: soupIndex !== -1 ? soupIndex + 1 : round.soups.length,
			};
		});

	public static playing = (roundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const round = getAlphabetSoupRound(gamesState, roundId);

			if (!round) return null;

			return round.playing;
		});

	public static over = (roundId: string) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const round = getAlphabetSoupRound(gamesState, roundId);

			if (!round) return null;

			return round.over;
		});

	public static score = (team: Team) =>
		createSelector(selectGames, (gamesState: GamesState) => {
			const phase = getAlphabetSoupPhase(gamesState);

			if (!phase) return null;

			const { rounds } = phase;

			const round = rounds.find(
				({ team: roundTeam }) => roundTeam === team,
			);

			if (!round) return null;

			return round.score;
		});
}

export const getAlphabetSoupRound = (
	gamesState: GamesState,
	alphabetSoupRoundId: string,
): AlphabetSoupRound | null => {
	const alphabetSoupPhase = getAlphabetSoupPhase(gamesState);

	if (!alphabetSoupPhase) return null;

	const { rounds } = alphabetSoupPhase;
	return rounds.find(({ _id }) => _id === alphabetSoupRoundId);
};
