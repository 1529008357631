import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AlphabetSoupRound } from '@api/models/alphabet-soup-round.entity';
import { AlphabetSoupPhaseUpdatedEvent } from '@core/models/games/events/alphabet-soup-phase.events';
import { AlphabetSoupPhaseSockets } from '@core/services/sockets/alphabet-soup-phase.sockets';
import { Store } from '@ngrx/store';

import { AlphabetSoupPhaseActions } from './alphabet-soup-phase.actions';
import { AlphabetSoupPhaseSelectors } from './alphabet-soup-phase.selectors';

@Injectable()
export class AlphabetSoupPhaseFacade {
	public roundIds$: Observable<string[]> = this.store.select(
		AlphabetSoupPhaseSelectors.roundIds,
	);

	public rounds$: Observable<AlphabetSoupRound[]> = this.store.select(
		AlphabetSoupPhaseSelectors.rounds,
	);

	public over$: Observable<boolean> = this.store.select(
		AlphabetSoupPhaseSelectors.over,
	);

	public get defaultRoundId(): string {
		return this._defaultRoundId;
	}

	private _defaultRoundId: string;

	constructor(
		private store: Store,
		private alphabetSoupPhaseSockets: AlphabetSoupPhaseSockets,
	) {
		this.roundIds$
			.pipe(filter((roundIds: string[]) => !!roundIds))
			.subscribe(
				([firstId]: string[]) => (this._defaultRoundId = firstId),
			);

		this.alphabetSoupPhaseSockets.phaseUpdated$.subscribe(
			({ phase }: AlphabetSoupPhaseUpdatedEvent) => {
				if (phase.over)
					this.store.dispatch(AlphabetSoupPhaseActions.finish());
			},
		);
	}
}
