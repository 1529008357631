import { Injectable } from '@angular/core';
import { AlphabetSoupAnswerUpdatedEvent } from '@core/models/games/events/alphabet-soup-phase.events';
import { AlphabetSoupPhaseSockets } from '@core/services/sockets/alphabet-soup-phase.sockets';
import { Store } from '@ngrx/store';

import { AlphabetSoupAnswerActions } from './alphabet-soup-answer.actions';

@Injectable()
export class AlphabetSoupAnswerFacade {
	constructor(
		private store: Store,
		alphabetSoupPhaseSockets: AlphabetSoupPhaseSockets,
	) {
		alphabetSoupPhaseSockets.answerUpdated$.subscribe(
			({ roundId, soupId, answer }: AlphabetSoupAnswerUpdatedEvent) =>
				this.store.dispatch(
					AlphabetSoupAnswerActions.updateSuccess({
						roundId,
						soupId,
						answer,
					}),
				),
		);
	}

	public hit(roundId: string, answerId: string): void {
		this.store.dispatch(
			AlphabetSoupAnswerActions.hit({ roundId, answerId }),
		);
	}
}
